
import { QrcodeStream } from 'vue-qrcode-reader';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: { QrcodeStream },
})
export default class SignInForm extends Vue {
  public error = '';

  async onInit(promise: Promise<any>): Promise<void> {
    const loading = this.$buefy.loading.open({
      container: null,
    });

    try {
      console.log('scan loading');
      await promise;
    } catch (error) {
      console.log('scan error', error);
      if (error.name === 'NotAllowedError') {
        // user denied camera access permisson
      } else if (error.name === 'NotFoundError') {
        // no suitable camera device installed
      } else if (error.name === 'NotSupportedError') {
        // page is not served over HTTPS (or localhost)
      } else if (error.name === 'NotReadableError') {
        // maybe camera is already in use
      } else if (error.name === 'OverconstrainedError') {
        // did you requested the front camera although there is none?
      } else if (error.name === 'StreamApiNotSupportedError') {
        // browser seems to be lacking features
      }
    } finally {
      // hide loading indicator
      loading.close();
    }
  }

  onDecode(decodedString: string | null): void {
    if (decodedString && decodedString.startsWith('https')) {
      return;
    }
    this.$emit('decode', decodedString);
  }
}
